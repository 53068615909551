<template>
    <div class="app-container mini-evaluation-management">
        <div class="button-line">
            <r-button plain @click="addNewConfig">新增</r-button>
            <r-button @click="initDatas">刷新</r-button>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler" />
        </div>
        <el-dialog :visible.sync="detailDialogShow" width="728px" :destroy-on-close="true"
            :close-on-click-modal="false">
            <template slot="title">
                <span>新增</span>
            </template>
            <warehouse :warehouseId="editId" v-if="detailDialogShow" @close="closeDialog" />
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import warehouse from "./warehouse.vue";
import {
    getWarehouseList,
    deleteWarehouse,
    getAreaList
} from "@/api/ruge/ams/basicConfig/warehouse";

export default {
    name: "ams-warehouse",
    components: {
        finalTable,
        warehouse
    },
    data() {
        return {
            editId: null,
            loadingFlag: false,
            detailDialogShow: false,
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                selection: {
                    need: false,
                },
                header: [
                    { prop: "affiliatedCompanyName", label: "所属公司", width: "" },
                    { prop: "affiliatedDepartmentName", label: "所属部门", width: "" },
                    { prop: "administrators", label: "仓库管理员", width: "" },
                    { prop: "warehouseName", label: "仓库名称", width: "" },
                    { prop: "warehouseCode", label: "仓库编码", width: "120" },
                    { prop: "availableCompanies", label: "可使用公司", width: "" },
                    { prop: "availableDepartments", label: "可使用部门", width: "" },
                    { prop: "affiliatedArea", label: "所属地区", width: "120" },
                    { prop: "warehouseAddress", label: "仓库地址", width: "" },
                    { prop: "operation", label: "操作", width: "120" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    affiliatedCompanyName: {
                        type: "input",
                        label: "所属公司",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入所属公司",
                        prefixIcon: "el-icon-search",
                    },
                    affiliatedDepartmentName: {
                        type: "input",
                        label: "编码",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入编码",
                        prefixIcon: "el-icon-search",
                    },
                    administrators: {
                        type: "input",
                        label: "仓库管理员",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入仓库管理员",
                        prefixIcon: "el-icon-search",
                    },
                    warehouseName: {
                        type: "input",
                        label: "仓库名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入仓库名称",
                        prefixIcon: "el-icon-search",
                    },
                    warehouseCode: {
                        type: "input",
                        label: "仓库编码",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入仓库编码",
                        prefixIcon: "el-icon-search",
                    },
                    availableCompanies: {
                        type: "input",
                        label: "可使用公司",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入可使用公司",
                        prefixIcon: "el-icon-search",
                    },
                    availableDepartments: {
                        type: "input",
                        label: "可使用部门",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入可使用部门",
                        prefixIcon: "el-icon-search",
                    },
                    affiliatedArea: {
                        type: "selector",
                        label: "所属地区",
                        value: "",
                        placeholder: "请选择所属地区",
                        actionType: "goSearch",
                        optionList: [],
                    },
                },
                // 表格内容配置
                detailConfig: {
                    //   creationDate: {
                    //     type: "dateFormat",
                    //   },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "edit",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "编辑",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "delete",
                                fontSize: "14px",
                                color: "#E47470",
                                cursorPointer: "pointer",
                                iconName: "el-icon-delete",
                                tooltips: "删除",
                            },

                        ],
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    affiliatedCompanyName: {
                        inline: true,
                        value: "",
                    },
                    affiliatedDepartmentName: {
                        inline: true,
                        value: "",
                    },
                    administrators: {
                        inline: true,
                        value: "",
                    },
                    warehouseName: {
                        inline: true,
                        value: "",
                    },
                    warehouseCode: {
                        inline: true,
                        value: "",
                    },
                    availableCompanies: {
                        inline: true,
                        value: "",
                    },
                    availableDepartments: {
                        inline: true,
                        value: "",
                    },
                    affiliatedArea: {
                        inline: true,
                        value: "",
                    },

                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            filterObj: {
                current: 1,
                rowCount: 10,
                affiliatedCompanyName: "",
                affiliatedDepartmentName: "",
                warehouseName: "",
                warehouseCode: "",
                availableCompanies: "",
                availableDepartments: "",
                affiliatedArea: "",
                administrators: "",
            },
        };
    },
    watch: {
        loadingFlag: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val);
            },
        },
    },
    created() {
        this.initDatas();
        this.findAreaList()
    },
    methods: {
        findAreaList() {  //地区列表
            getAreaList().then((res) => {
                if (res) {
                    let compantList = res.map((item) => {
                        return {
                            label: item.areaName,
                            value: item.areaName
                        }
                    })
                    this.dataset.searchLineConfig.affiliatedArea.optionList = compantList
                    console.log("this.areaList ", this.areaList)
                }
            })
        },
        closeDialog() {
            this.detailDialogShow = false;
            this.initDatas();
        },
        addNewConfig() {
            this.editId = null
            this.detailDialogShow = true;
        },
        initDatas() {
            this.loadingFlag = true;
            getWarehouseList(this.filterObj)
                .then((res) => {

                    console.log("xxx", this.dataset.searchLineConfig.affiliatedArea.optionList)
                    this.dataset.tableData = res.data.rows;
                    this.dataset.pageVO.total = res.data.totalPages;
                    console.log("this.dataset.tableData", this.dataset.tableData)

                })
                .finally(() => {
                    this.loadingFlag = false;
                });
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.filterObj.current = 1;
                this.filterObj = { ...this.filterObj, ...datas.params };
                this.initDatas();
            } else if (datas.type === "paginationChange") {
                this.filterObj.current = datas.params.current.page;
                this.filterObj.rowCount = datas.params.current.limit;
                this.initDatas();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "delete":
                        this.deleteConfig(datas.row);
                        break;
                    case "edit":
                        this.editConfig(datas.row);
                        break;
                }
            }
        },
        editConfig(rowDatas) {
            const { warehouseId } = rowDatas
            this.detailDialogShow = true
            this.editId = warehouseId
            console.log("rowDatas", this.editId)
        },
        async deleteConfig(rowDatas) {
            await this.$confirm(`是否确认删除`, this.$t("commons.warning"), {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
            });
            deleteWarehouse({
                warehouseId: rowDatas.warehouseId
            }).then(() => {
                this.$message.success("删除成功！");
                this.initDatas();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mini-evaluation-management {
    .button-line {
        margin-bottom: 10px;
    }
}
</style>