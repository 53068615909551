import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getWarehouseList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/warehouse/getWarehousePageList',
    method: 'get',
    params,
  });
}


/**
 *  详情
 * @param params
 */
export function getWarehouseDetail(params) {
    return request({
      url: envInfo.bgApp.amsPath + '/warehouse/getWarehouseDetail',
      method: 'get',
      params,
    });
  }
  
  
  
/**
 *  所属管理公司
 * @param params
 */
export function   getProjectList(params) {
    return request({
      url: envInfo.bgApp.orgPath + '/tenant/company/list',
      method: 'get',
      params,
    });
  }
  

    
/**
 *  所属公司部门与可选部门
 * @param params
 */
export function  getCompany(params) {
    return request({
      url: envInfo.bgApp.orgPath + '/tenant/dept/list',
      method: 'get',
      params,
    });
  }
  
/**
 * 仓库管理员
 * @param params
 */
export function  getEmpList(params) {
    return request({
      url: envInfo.bgApp.orgPath + '/tenant/employee/empList',
      method: 'get',
      params,
    });
  }

  /**
 * 仓库管理员
 * @param params
 */
export function  getCompanyListAll(params) {
    return request({
      url: envInfo.bgApp.orgPath + '/tenant/company/listAll',
      method: 'get',
      params,
    });
  }
 
  /**
 * 新增
 * @param params
 */
export function addWarehouse(params) {
    return request({
      url: envInfo.bgApp.amsPath + '/warehouse/add',
      method: 'post',
      data: params,
    });
  }
  
/**
 * 删除
 * @param params
 */
export function  deleteWarehouse(params) {
    return request({
      url: envInfo.bgApp.amsPath + '/warehouse/delete',
      method: 'get',
      params,
    });
  }
 
  
 /**
 * 获取地区代码列表
 * @param params
 */
export function  getAreaList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/code/area/list',
    method: 'get',
    params,
  });
}